<template>
  <div class="resetPasswordPage bgfff">
    <div class="app h5MaxWidth">
      <header>
        <div class="header h5MaxWidth clear display alignCenter">
          <div
            class="back display alignCenter justifyCenter"
            onclick="window.history.go(-1)"
          >
            <i class="iconfont icon-arrow-left"></i>
          </div>
          <h2 class="flex1">Reset Login Password</h2>
          <div class="navskip" id="sideMenu"></div>
        </div>
      </header>
      <div class="main">
        <div class="resetPassword forms">
          <form id="resetPassword" action="#" method="post" onsubmit="">
            <ul>
              <li class="areaCode">
                <div class="inputArea display alignCenter">
                  <div class="leftSideBox display alignCenter">
                    <i class="i_inputIcon iconfont icon-mobile"></i>
                  </div>
                  <div class="trueInpt display alignCenter flex1">
                    <p id="areaCode"></p>
                    <input
                      type="number"
                      name="mobile"
                      id="mobile"
                      class="mobile fillArea flex1"
                      placeholder="Mobile number"
                      maxlength="11"
                      size="11"
                    />
                  </div>
                </div>
              </li>

              <li class="oldPasswordBox">
                <div class="inputArea display alignCenter">
                  <div class="leftSideBox display alignCenter">
                    <i class="i_inputIcon iconfont icon-lock-2"></i>
                  </div>
                  <div class="trueInpt display alignCenter flex1">
                    <input
                      type="password"
                      name="login_OldPassword"
                      id="login_OldPassword"
                      placeholder="Old Password"
                      class="password fillArea eyeClose flex1"
                    />
                    <div
                      id="eye"
                      class="eye secret rightSideBox display alignCenter"
                    >
                      <i class="r iconfont icon-eye"></i>
                      <i class="s iconfont icon-eye-slash"></i>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="inputArea display alignCenter">
                  <div class="leftSideBox display alignCenter">
                    <i class="i_inputIcon iconfont icon-lock"></i>
                  </div>
                  <div class="trueInpt display alignCenter flex1">
                    <input
                      type="password"
                      name="login_NewPassword"
                      id="login_NewPassword"
                      placeholder="New Password"
                      class="password fillArea eyeClose flex1"
                    />
                  </div>
                </div>
              </li>
              <li class="verificationCodeBox">
                <div class="inputArea display alignCenter">
                  <div class="leftSideBox display alignCenter">
                    <i class="i_inputIcon iconfont icon-barcode"></i>
                  </div>
                  <div class="trueInpt display alignCenter flex1">
                    <input
                      type="text"
                      name="verificationCode "
                      id="verificationCode"
                      class="checkCode fillArea flex1"
                      placeholder="Verification code (OTP)"
                      maxlength="5"
                      size="5"
                    />
                    <div
                      id="getCode"
                      class="getCode rightSideBox display alignCenter"
                      @click="getCode()"
                    >
                      <p>Send</p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="actionBar display alignCenter alignStretch">
              <div
                class="goSignIn clickBtn radius7PX display alignCenter justifyCenter"
                onclick="window.history.go(-1)"
              >
                SIGN IN
              </div>
              <input
                class="changeBtn clickBtn radius7PX flex1 display alignCenter justifyCenter"
                type="button"
                value="Reset password"
                @click="resetPassword()"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {};
  },
  components: {},
  methods: {
    getCode() {
      var no = $.trim($("#mobile").val());
      this.getAjax(
        "api/login/sendCode",
        {
          mobile: no,
          type: "",
        },
        "post"
      ).then((res) => {
        if (res.code === 200) {
          this.tips("send!");
        } else {
          this.tips(res.msg, "danger");
        }
      });
    },
    resetPassword() {
      var account = $("#mobile").val();
      var verificationCode = $("#verificationCode").val();
      var password_N = $("#login_NewPassword").val();
      if (account === "") {
        this.toast("", "Mobile phone can not be empty.", "login", 2000, 1);
        $("#mobile").focus();
        return;
      }
      if (verificationCode === "") {
        this.toast(
          "",
          "Verification code (OTP) can not be empty.",
          "verificationCode",
          2000,
          1
        );
        $("#inviteCode").focus();
        return false;
      }

      if (password_N === "") {
        this.toast("", "New Password can not be empty.", "login", 2000, 1);
        $("#login_NewPassword").focus();
        return;
      }
      this.getAjax(
        "api/login/recover",
        {
          password: password_N,
          mobile: account,
          code: verificationCode,
        },
        "post"
      ).then((res) => {
        if (res.code === 200) {
          this.tips("success!");
        } else {
          this.tips(res.msg, "danger");
        }
      });
    },
  },
  mounted() {
    function GetQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return r[2];
      return null;
    }
    $(document).ready(function () {
      var resetType = GetQueryString("resetType");
      $("#login_NewPassword,#login_OldPassword").togglePassword({
        el: "#eye",
      });

      $(".header h2").text("Retrieve Login Password");
      $(".oldPasswordBox,header").css({ display: "none" });
      $(".mobileBox,.verificationCodeBox").css({ display: "block" });
    });
  },
};
</script>

<style scoped lang="less">
</style>
